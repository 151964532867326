<template>
  <div>
    <div class="tile pb-4">
      <h5 class="tile-title mb-3">Server</h5>
      <div v-if="isLoading" class="d-flex justify-content-center mb-4">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-4 col-sm-6 mb-md-0 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <i class="fas fa-memory server"></i> RAM
              </h5>
              <hr />
              <div class="card-text">
                <div>
                  Total:
                  <span class="badge bg-light text-dark">{{
                    serverInfo_data.totalRam
                  }}</span>
                </div>
                <div>
                  Percentage:<span class="badge bg-light text-dark">{{
                    serverInfo_data.freeMemPct
                  }}</span>
                </div>
                <div>
                  Free:
                  <span class="badge bg-light text-dark">{{
                    serverInfo_data.freeRam
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-md-0 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <i class="fas fa-microchip server"></i> CPU
              </h5>
              <hr />
              <div class="card-text">
                <div>
                  Core(s):<span class="badge bg-light text-dark">
                    {{ serverInfo_data.cpuCores }}</span>
                </div>
                <div>
                  Usage:<span class="badge bg-light text-dark">
                    {{ serverInfo_data.cpuUsage }}</span>
                </div>
                <div>
                  Free:<span class="badge bg-light text-dark">{{
                    serverInfo_data.cpuFree
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-md-0 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <i class="fas fa-server server"></i> System
              </h5>
              <hr />
              <div class="card-text">
                <div>
                  OS:
                  <span class="badge bg-light text-dark">{{
                    serverInfo_data.os
                  }}</span>
                </div>
                <div>
                  Uptime:<span class="badge bg-light text-dark">{{
                    serverInfo_data.upTime
                  }}</span>
                </div>
                <div>
                  Average Load:<span class="badge bg-light text-dark">
                    {{ serverInfo_data.loadAvg }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tile py-5">
      <h5 class="tile-title mb-3">Portals</h5>
      <div class="row card-group d-flex justify-content-between">
        <div class="col-lg-2 col-sm-6 px-0 card-image text-center mb-lg-0 mb-3">
          <div class="card-body">
            <p class="card-text">
              <img alt="logo" src="../assets/img/europe.png" class="img-thumbnail" />
            </p>
            <p class="card-text">
              <a href="https://crm.triomarkets.eu/console" class="btn btn-primary portal" target="_blank">EDR CRM</a>
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 px-0 card-image text-center mb-lg-0 mb-3">
          <div class="card-body">
            <p class="card-text">
              <img alt="logo" src="../assets/img/global.png" class="img-thumbnail" />
            </p>
            <p class="card-text">
              <a href="https://crm.triomarkets.com/console" class="btn btn-primary portal" target="_blank">BENOR CRM</a>
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 px-0 card-image text-center mb-lg-0 mb-3">
          <div class="card-body">
            <p class="card-text">
              <img alt="logo" src="../assets/img/zoiper.png" class="img-thumbnail" />
            </p>
            <p class="card-text">
              <a href="https://www.zoiper.com/en/voip-softphone/download/current" class="btn btn-primary portal"
                target="_blank">Zoiper</a>
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 px-0 card-image text-center mb-lg-0 mb-3">
          <div class="card-body">
            <p class="card-text">
              <img alt="logo" src="../assets/img/teams.png" class="img-thumbnail" />
            </p>
            <p class="card-text">
              <a href="https://go.microsoft.com/fwlink/p/?LinkID=869426&culture=en-us&country=WW&lm=deeplink&lmsrc=groupChatMarketingPageWeb&cmpid=directDownloadWin64"
                class="btn btn-primary portal" download>Teams</a>
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 px-0 card-image text-center mb-lg-0 mb-3">
          <div class="card-body">
            <p class="card-text">
              <img alt="logo" src="../assets/img/outlook.png" class="img-thumbnail" />
            </p>
            <p class="card-text">
              <a href="https://outlook.office.com/mail/inbox" class="btn btn-primary portal" target="_blank">Outlook</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tile pb-4">
      <h5 class="tile-title mb-3">Servers</h5>
      <div class="row">
        <div class="col-sm-6 mb-sm-0 mb-3">
          <div class="card text-center">
            <div class="card-body">
              <h5 class="card-title">MT4 Demo</h5>
              <p class="card-text">
                <span class="badge bg-light text-dark fs-6">94.237.100.191</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h5 class="card-title">Live Quotes</h5>
              <p class="card-text">
                <span class="badge bg-light text-dark fs-6">94.237.88.27</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: true,
      serverInfo_data: {
        totalRam: "",
        freeRam: "",
        freeRamPct: "",
        cpuCores: "",
        cpuUsage: "",
        cpuFree: "",
        os: "",
        upTime: "",
        loadAvg: "",
      },
    };
  },
  created() {
    this.getServerInfo();
  },
  methods: {
    async getServerInfo() {
      this.$axios({
        url: "/maintenance/server/server-info",
      })
        .then(({ data: { success, serverInfo } }) => {
          if (success) {
            this.isLoading = false;
            this.serverInfo_data = serverInfo;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>